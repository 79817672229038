*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    list-style: none;
  }
  
  .ant-btn-primary {
    background-color: #1890ff !important;
  }
  .ant-btn-primary:hover {
    background-color: #40a9ff !important;
  }

  .ant-form-item-label>label{
    color: #01509A !important;
    font-size: 14px !important;
   }

   .ant-table-tbody > tr:nth-child(even) {
    border-radius: 8px 0px 0px 8px !important;
    background-color: #e8e6e6 !important;
  }
  
  .custom-modal .ant-modal-content {
    padding: 0 0 24px 0 !important;
  }
  .custom-modal .ant-modal-confirm-btns {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
  
  }
  
  .custom-modal .ant-btn {
    margin-right: 6px; 
  }
  
  .custom-modal .ant-btn-dangerous {
    background-color: #F44336 !important;
    border: none;
    color: white !important;
  }
  .custom-modal .ant-btn-dangerous:hover{
    background-color: #eb3629 !important;
    border: none;
    color: white !important;
  }
  
  .custom-modal .ant-modal-confirm-body {
    justify-content: center;
  }
  .custom-modal .ant-modal-confirm-title{
    text-align: center;
    padding-top: 12px;
    padding-bottom: 12px;
    background-color: #F44336;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    color: #fff !important;
  }
  
  .custom-modal .ant-modal-confirm-content{
    text-align: center;
  }
  
  .custom-modal-checkout-warning .ant-modal-confirm-title{
    text-align: center;
  }
  
  .custom-modal-checkout-warning .ant-modal-confirm-btns {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
  
  }
  
  .custom-modal-checkout-warning .ant-btn {
    margin-right: 6px; 
  }

  .custom-warning-modal .ant-modal-content {
    text-align: center;
  }
   .custom-warning-modal .ant-modal-confirm-btns{
    text-align: center;
   }
   .custom-warning-modal .ant-btn-warning{
    text-align: center;
    /* padding-top: 12px;
    padding-bottom: 12px; */
    background-color: #fbad15;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    color: #fff !important;
   }

   ::-webkit-scrollbar{
    width: 8px;
    height: 12px;
  }
  ::-webkit-scrollbar-track{
    background-color: #f1f1f1;
  }
  ::-webkit-scrollbar-thumb{
    background-color: rgb(190, 190, 190);
  }