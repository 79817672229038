.ant-menu-item-selected{
    background-color: #1890ff !important;
    color: #fff !important;
    border-radius: 35px !important;
}
li{
    margin-bottom: 10px !important;
}
.ant-layout-header{
    height: 80px !important;
}